@import-normalize;
* { margin: 0; padding: 0; color:#333333; line-height: 1.5; }
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
               'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
               sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media all and (width > 480px) {
  body { margin: 60px 100px; font-size: 90%; }
}
@media all and (width <= 480px) {
  body { margin: 30px 30px 40px; font-size: 90%; }
}
a { color: #408888; }
img { border: none; }
h2 { padding-top: 40px; }
h3 { padding-top: 20px; }
dt { padding-top: 10px; font-weight: bold; }
dt, dd, p { padding-left: 10px; }
ul { padding-left: 20px; }
p.caution { margin-top: 30px; padding: 5px; border: 3px solid #c00; }
.screenshotos img { padding-top: 10px; }
.screenshotos .left img { float: left; padding-right: 20px; }
